.artCard {
  z-index: 600;
  transition: all 0.2s ease-in;
}

.artCard:hover {
  transform: translateY(-5px);
}

.gradientBorderBox {
  padding: 1px;
  border-radius: 10px;
}

.artCard:hover .gradientBorderBox {
  background: linear-gradient(to right, #ad18c7, #11c4e3);
}
.cardBody {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 0.2rem;
  z-index: 10;
  cursor: pointer;
}

.imageStyle {
  height: 100%;
  width: 100%;
  border-radius: 20px;
  object-fit: cover;
}

.USDStyle {
  /* height: 50%; */
  /* width: 100%;
  border-radius: 20px;
  object-fit: cover; */
  /* transform: rotate(-90deg) translate(-24%, -189px);
  height: 266px;
  width: 645.19px;
  object-fit: cover;
  border-radius: 20px; */

  /* transform: rotate(-90deg) ; */
  /* translate(-17%, -49%); */

  /* transform: rotate(-90deg) translate(-17%, -49%);
  height: 80%;
  width: 239.5%;
  border-radius: 20px; */

  transform: rotate(-90deg) translate(-22%, -56%);
  height: 81%;
  object-fit: scale-down;
  width: 252.5%;
}

.artCardBody {
  margin-top: 24px;

}

.artCardInfo {
  display: flex;
  justify-content: space-between;
}

.artCardCreator {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.artCardOwner {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.dividerStyle {
  background-color: #8e8e8e;
  margin: 10px 0;
}

.artCardFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}