.collectionCard {
  z-index: 600;
  transition: all 0.2s ease-in;
}

.collectionCard:hover {
  transform: translateY(-5px);
}

.gradientBorderBox {
  padding: 1px;
  /* border-radius: 10px; */
}

.collectionCard:hover .gradientBorderBox {
  background: linear-gradient(to right, #ad18c7, #11c4e3);
}
.cardBody {
  display: flex;
  flex-direction: column;
  /* border-radius: 20px; */
  padding: 0.6rem;
  z-index: 10;
  cursor: pointer;
}

.imageStyle {
  height: 100%;
  width: 100%;
  /* border-radius: 20px; */
  object-fit: cover;
}

.overlayed {
  position: absolute;
  top: 0px;
  left: 0px;
  width: auto;
  height: auto;
}

/* .USDStyle {
  transform: rotate(-90deg) translate(-20%, -56%);
  height: 81%;
  object-fit: scale-down;
  width: 252.5%;
} */

.collectionCardBody {
  margin-top: 24px;

}

.collectionCardInfo {
  display: flex;
  justify-content: space-between;
}

.collectionCardCreator {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.collectionCardOwner {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.dividerStyle {
  background-color: #8e8e8e;
  margin: 10px 0;
}

.collectionCardFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}