.giftCard {
  z-index: 600;
  transition: all 0.2s ease-in;
  padding: 0.6rem;

}

.giftCard:hover {
  transform: translateY(-7px);
}

.gradientBorderBox {
  padding: 1px;
}

/* .giftCard:hover .gradientBorderBox {
  background: linear-gradient(to right, #ad18c7, #11c4e3);
} */
.cardBody {
  display: flex;
  flex-direction: column;
  padding: 0.6rem;
  z-index: 10;
}

.imageStyle {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.giftCardBody {
  margin-top: 24px;
}

.giftCardInfo {
  display: flex;
  justify-content: space-between;
}

.giftCardCreator {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.giftCardOwner {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.dividerStyle {
  background-color: #8e8e8e;
  margin: 10px 0;
}

.giftCardFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}