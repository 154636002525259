.heroGradientText {
  letter-spacing: 0.1rem;
}

.heroGradientTextLight {
  background: -webkit-linear-gradient(#ad18c7, #11c4e3);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.heroGradientText::before {
  position: absolute;
  content: "AWESOME";
  text-shadow: 0px 0px 20px #e552ff, 0px 0px 30px #e552ff, 0px 0px 10px #e552ff,
    0px 0px 1px #e552ff;
  filter: blur(10px) brightness(0);
  animation: flicker 2s linear;
  animation-iteration-count: infinite;
}

@keyframes flicker {
  0% {
    filter: blur(5px) brightness(1);
  }
  3% {
    filter: blur(5px) brightness(1.5);
  }
  6% {
    filter: blur(5px) brightness(1);
  }
  7% {
    filter: blur(5px) brightness(1);
  }
  8% {
    filter: blur(5px) brightness(1.3);
  }
  9% {
    filter: blur(5px) brightness(1);
  }
  10% {
    filter: blur(5px) brightness(1);
  }
  20% {
    filter: blur(5px) brightness(1);
  }
  50% {
    filter: blur(5px) brightness(1.7);
  }
  99% {
    filter: blur(5px) brightness(1);
  }
  100% {
    filter: blur(5px) brightness(1.3);
  }
}



.mainBoxStyle {
  background: linear-gradient(
    142.75deg,
    rgba(255, 255, 255, 0.05) 0%,
    rgba(255, 255, 255, 0) 99.99%,
    rgba(255, 255, 255, 0.02) 100%
  );
  height: 100vh;
  backdrop-filter: blur(10px);
}
