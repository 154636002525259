.container {
  
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  padding-top: 20px;
}
.deck {
  padding-top: 20px;
  position: absolute;
  width: 210px;
  height: 100px;
  will-change: transform;
  display: flex;
  align-items: center;
  justify-content: center;
  touch-action: none;
  /* overflow-x: hidden; */
}

.deck > div {
  /* background-color: white;
  padding-top: 20px; */
  background-size: auto 85%;
  background-repeat: no-repeat;
  background-position: center center;
  width: 230px;
  /* max-width: 150px; */
  height: 365px;
  /* max-height: 285px; */
  will-change: transform;
  border-radius: 10px;
 
}
