.modalStyle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  width: 600px;
  padding: 32px;
}
.dropdownButton {
  position: 'relative';
  z-index:  1;
}

.dropdownDrawer {
  position: 'absolute';
  top: '100%';
  left: 0;
  width: 200;
}


.modalStyleMobile {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid #000;
  border-radius: 10px;
  width: 80%;
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 24px;
  padding-bottom: 24px;
}
.drawer {
  position: absolute;
  top: "100%";
  left: 0;
  width: "100%";
  max-width: 360;
}

.buttonStyle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding: 1rem 0;
  border-radius: 10px;
  cursor: pointer;
  padding-left: calc(100% - 90%);
}

.buttonStyleMobile {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding: 1rem 0;
  border-radius: 10px;
  cursor: pointer;
  padding-left: calc(100% - 70%);
}
